import React, { useRef, useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'
import { el, enGB } from 'date-fns/locale'
// import GeneralIntro from "../components/Global/GeneralIntro"

import Calendar from "./../images/calendar.svg"
import searchSvg from "./../images/search-solid.svg"
import folderIcon from "../images/folder-regular.svg";

import { GatsbyImage } from "gatsby-plugin-image";

import "./../components/Posts/BlogPageTemplate.css";


import '@wordpress/block-library/build-style/style.css'

function BlogPageTemplate(props) {
    console.log(props)
    let data = props.data;
    const { currentPage, numberOfPages, language } = props.pageContext;

    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const baseSlug = data.allWpCategory.nodes[0].slug;

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function sendSearch(term) {
        if (term !== "") {
            fetch('https://admin.trustdev.gr/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `query ( $search: String , $language: LanguageCodeFilterEnum) { 
								posts(where: {search: $search , language: $language}) {
									edges {
										node {
											title
                                            slug
										}
									}
								}	
							}`,
                    variables:
                    {
                        search: term,
                        language: props.pageContext.language
                    }
                }),
            })
                .then(response => {
                    return response.json()
                })
                .then(responseAsJson => {
                    console.log(responseAsJson.data);
                    setSearchResults(responseAsJson.data.posts.edges)
                })
        } else {
            setSearchResults([])
        }
    }

    const getUrl = (pageNum) => {
        const pageSlug = pageNum === 1 ? "" : `${pageNum}/`;
        // Check if the language is "EL", if so, do not include the language prefix
        const langPrefix = language === "EL" ? "" : `/${language.toLowerCase()}`;
        return `${langPrefix}/${baseSlug}/${pageSlug}`;
    };


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            breadcrumbs={data.allWpCategory.nodes[0].seo.breadcrumbs}

            locale={props.pageContext.language}
            metaData={constructMetaData(data.allWpCategory.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >

            <section>
                <div className="container-cs w-full m-auto my-12 md:my-24">
                    <div className="w-full flex flex-wrap">
                        <div className="w-full md:w-3/4 flex flex-wrap order-1 md:order-1">
                            {data.allWpPost.nodes.map((post, i) => {
                                return (
                                    <article key={`program-post-${i}`} className='w-full md:w-1/2 px-4 pt-4 md:pt-12'>
                                        <a href={`${props.pageContext.language === "EN" ? "/en" : ""}${post.uri}`}>
                                            <div className='prog-wrapper'>
                                                <GatsbyImage
                                                    image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                    alt={post.featuredImage.node.altText}
                                                    className="m-auto sc-m-img0"
                                                />
                                                <div className="blog-con-wrapper">
                                                    <div>
                                                        {(post?.tags && post.tags?.nodes?.length > 0) && post.tags.nodes.map((tag, j) => (
                                                            <React.Fragment>
                                                                <span className='program-tag' key={`program-tag-${j}`}>{tag.name}</span>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    <h3 className="pt-4">{post.title}</h3>

                                                    <div className="w-full z-10">
                                                        <div className="flex  blog-info pt-2">
                                                            <div className="flex justify-center">
                                                                <div>
                                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px", paddingTop: "2px" }} />
                                                                </div>
                                                                <div className="blog-date">{props.pageContext.language === "EL" ? format(new Date(post.date), 'dd MMMM, yyyy', { locale: el }) : format(new Date(post.date), 'dd MMMM, yyyy', { locale: enGB })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </article>
                                );
                            })}
                            <div className="flex justify-center w-full">

                                <ul className="flex justify-center py-8 px-4">
                                    {/* Link to the previous page if not on the first page */}
                                    {currentPage > 1 && (
                                        <li className="mr-4 relative">
                                            <Link
                                                to={getUrl(currentPage - 1)}
                                                className="py-2 px-4 pagination-link bp-arrow"
                                            >
                                                {'<'}
                                            </Link>
                                        </li>
                                    )}

                                    {/* Links to all visible pages (simplified for clear range) */}
                                    {Array.from({ length: numberOfPages }, (_, index) => {
                                        const indexPlusOne = index + 1;
                                        // Only show links for a few pages around the current page to avoid clutter
                                        if (Math.abs(currentPage - indexPlusOne) <= 2 || indexPlusOne === 1 || indexPlusOne === numberOfPages) {
                                            return (
                                                <li key={index} className="mr-4">
                                                    <Link
                                                        to={getUrl(indexPlusOne)}
                                                        className={currentPage === indexPlusOne ? "active-pagination-link py-2 px-4" : "py-2 px-4 pagination-link"}
                                                    >
                                                        {indexPlusOne}
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    })}

                                    {/* Link to the next page if not on the last page */}
                                    {currentPage < numberOfPages && (
                                        <li className="mr-4 relative">
                                            <Link
                                                to={getUrl(currentPage + 1)}
                                                className="py-2 px-4 pagination-link bp-arrow-r"
                                            >
                                                {'>'}
                                            </Link>
                                        </li>
                                    )}
                                </ul>

                                {/* <ul className="flex justify-center py-8 px-4">
                                    {props.pageContext.currentPage === 2 && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug.replace("blog","")}` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}`}
                                                className={"py-2 px-4 pagination-link bp-arrow"}
                                            >
                                                1
                                            </Link>
                                        </li>
                                    )}

                                    {props.pageContext.currentPage > 2 && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug.replace("blog","")}` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${props.pageContext.currentPage - 1}/`}
                                                className={"py-2 px-4 pagination-link bp-arrow"}
                                            >
                                            </Link>
                                        </li>
                                    )}

                                    {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => {
                                        if ((props.pageContext.currentPage + 1 >= index) && (props.pageContext.currentPage - 1 <= index)) {
                                            return (
                                                <li key={index} className={`mr-4 `}  >
                                                    <Link
                                                        to={index === 0 ? `${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug.replace("blog","")}` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}` : `${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug.replace("blog","")}/` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${index + 1}/`}
                                                        className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                                                    >
    
                                                        {index + 1}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    }))}

                                    {props.pageContext.currentPage < props.pageContext.numberOfPages && (
                                        <li className="mr-4 relative"  >
                                            <Link
                                                to={`${props.pageContext.language === "EL" ? `/blog/${data.allWpCategory.nodes[0].slug.replace("blog","")}` : "/" + props.pageContext.language.toLowerCase() + `/blog/${data.allWpCategory.nodes[0].slug}/`}${props.pageContext.currentPage + 1}/`}
                                                className={"py-2 px-4 pagination-link bp-arrow-r"}
                                            >
                                            </Link>
                                        </li>
                                    )}

                                </ul> */}
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 flex flex-wrap order-2 md:order-2 px-4">
                            <div className="w-full">
                                <div className="relative w-full">
                                    <h3 className="side-cat-h3">
                                        {props.pageContext.language === "EL" ? "Αναζήτηση" : "Search"}
                                    </h3>
                                    <div className="flex relative">
                                        <input value={search} onChange={(e) => setSearch(e.target.value)} className="search-i" type="text" />
                                        <button onClick={() => sendSearch(search)} className="search-b">
                                            <img src={searchSvg} width={15} />
                                        </button>
                                    </div>
                                </div>
                                {(searchResults && searchResults.length > 0) && (
                                    <ul className="search-ul">
                                        {searchResults.map((sc, i) => (
                                            <li key={`search-result-${i}`}>
                                                <Link to={`${props.pageContext.language === "EN" ? "/en" : ""}/blog/${sc.node.slug}`}>
                                                    {sc.node.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <div className="relative mt-8 w-full">
                                    <h3 className="side-cat-h3">
                                        {props.pageContext.language === "EL" ? "Κατηγορίες" : "Categories"}
                                    </h3>
                                    <ul>
                                        {data.otherCategories.nodes.map((cat, i) => {
                                            if ((cat.name !== "Blog") && (cat.name !== "blog") && (cat.name !== "Uncategorized")) {
                                                return (
                                                    <li className="pb-4" key={`cat-${i}`}>
                                                        <Link to={props.pageContext.language === "EL" ? `/${cat.slug}` : `/en/${cat.slug}`} className="flex items-center">
                                                            <div>
                                                                <img src={folderIcon} style={{ width: "15px" }} />
                                                            </div>
                                                            <span className="pl-2 cat-links-li">
                                                                {cat.name}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                )
                                            }

                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BlogPageTemplate

export const pageQuery = graphql`query GET_BLOG_CATEGORY_PAGE($id: String, $templateId: String , $language: WpLanguageCodeEnum, $startPoint: Int, $postsPerPage: Int) {
    allWpCategory(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {
            slug
            name
            description
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
                breadcrumbs {
                    text
                    url
                }
            }
        }
    }

    allWpPost(skip: $startPoint, limit: $postsPerPage, sort: { fields: [date] , order: DESC }, filter: {categories: {nodes: {elemMatch: {id: {eq: $id}}}}}) {
        nodes {
            uri
            title
            slug
            date
            featuredImage{
                node{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }
            categories{
                nodes{
                    name
                    slug
                }
            }

            tags{
                nodes{
                    name
                }
            }
        }
    }

    otherCategories: allWpCategory(limit: 100, filter: {language: {code: {eq: $language}}}){
        nodes{
          name
          slug
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
